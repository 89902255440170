
import { defineComponent, ref, reactive, onMounted } from "vue";
import useClipboard from 'vue-clipboard3';
import Modal from "../components/base/modal.vue";
import CreateForm from "../components/base/createForm.vue";
import { Model, Fields, Form } from "../type/form";
import { getCurrentInstance } from "vue";
import { ElMessageBox, ElMessage } from 'element-plus';

const cxt: any = getCurrentInstance(); //Vue2 this
// const bus = cxt.appContext.config.globalProperties.$bus
import {
  contract,
  getAccount,
  getAllFundings,
  Funding,
  newFunding,
  returnDetail,
  addListener,
  getProve,
  newProve, authenticate,
} from "../api/contract";
import { message } from "ant-design-vue";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import { useRouter } from "vue-router";

const Url = 'https://ipfscc.bsngate.com:18602/ipfs/peer1/0e109dc62b50452fa9a4bc58b94c1a82/api/v0/cat?arg='

const columns = [
  {
    dataIndex: "title",
    key: "title",
    title: "title",
  },
  {
    title: "image",
    dataIndex: "coverImage",
    key: "coverImage",
    slots: { customRender: "coverImage" },
  },

  {
    title: "goal",
    dataIndex: "goal",
    key: "goal",
  },
  {
    title: "amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "endTime",
    dataIndex: "endTime",
    key: "endTime",
    slots: { customRender: "time" },
  },
  {
    title: "status",
    dataIndex: "success",
    key: "success",
    slots: { customRender: "tag" },
  },
  {
    title: "info",
    dataIndex: "action",
    key: "action",
    slots: { customRender: "action" },
  },
];
const account = ref('account')
async function handleClick() {

  await authenticate()
  account.value = await getAccount()

}

export default defineComponent({
  name: "Home",
  components: {
    Modal,
    CreateForm,
    CheckCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined,
  },
  setup() {
    onMounted(() => {
      // bus.on('printMessage',()=>{
      //       closeModal()
      //   })

      handleClick()
    });

    function touchCopy () {
      // 调用
      copy()
    }
    // 使用插件
    const { toClipboard } = useClipboard()
    const copy = async () => {
      try {
        const url="https://www.eth20.co/?address="+account.value;

        await toClipboard(url)
        message.success('copy success!');

      } catch (e) {
        console.error(e)
      }
    }

    let show = ref<boolean>(false);
    const isOpen = ref<boolean>(false);
    const state = reactive<{ loading: boolean; data: Funding[] }>({
      loading: true,
      data: [],
    });

    async function fetchData() {
      state.loading = true;
      try {
        state.data = await getAllFundings();
        console.log(state.data.length);
        state.loading = false;
        // var res = await returnDetail(3);
        // console.log(res);
      } catch (e) {
        console.log(e);
        message.error("fail!");
      }
    }

    async function openModal() {
      model.account = await getAccount();
      show.value = true;
      isOpen.value = true;
    }
    function closeModal() {
            show.value = false;

      isOpen.value = false;
    }

    let model = reactive<Model>({
      account: "",
      coverImage: "",
      title: "",
      info: "",
      amount: 0,
      date: null,
      details: [],
    });

    const fields = reactive<Fields>({
      account: {
        type: "input",
        label: "input",
        disabled: true,
      },
      title: {
        type: "input",
        label: "input",
        rule: {
          required: true,
          trigger: "blur",
        },
      },
      info: {
        type: "textarea",
        label: "textarea",
        rule: {
          required: true,
          trigger: "blur",
        },
      },
      coverImage: {
        type: "upload",
        label: "upload",
        rule: {
          // required: true,
          trigger: "blur",
        },
      },
      amount: {
        type: "number",
        label: "number",
        min: 0,
      },
      date: {
        type: "time",
        label: "time",
      },
      details: {
        type: "autocomplete",
        label: "autocomplete",
      },
    });

    const form = reactive<Form>({
      submitHint: "submit",
      cancelHint: "cancel",
      cancel: () => {
        model.coverImage="",
        model.account="",
        model.title="",
        model.info="",
        model.amount=0,
        model.date=null,
        model.details=[]
        
        console.log(model);

        closeModal();
      },
      finish: async () => {
        console.log(model);
        
        const seconds = Math.ceil(new Date(model.date).getTime() / 1000);
        try {
          const res = await newFunding(
            model.account,
            model.title,
            model.info,
            model.coverImage,
            model.details,
            model.amount,
            seconds
          );
         
          message.success("success");
          closeModal();
          fetchData();
        } catch (e) {
          console.log(e);
          message.error("fail");
        }
      },
    });

    const router = useRouter();
    const clickFunding = (index: number) => {
      router.push(`/funding/${index}`);
    };
    addListener(fetchData);
    fetchData();

    return {
      openModal,
      isOpen,
      model,
      fields,
      form,
      Url,
      state,
      columns,
      account,
      touchCopy,
      clickFunding,
      closeModal,
      show
    };
  },
});
